import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { RequestState } from "../../RequestState";
import { AuthService } from "../../servises/AuthService";
import { RouteName } from "../../RouteName";
import { User } from "../../models/User";
import UserContext from "../context/UserContext";
import "../App.css";
import { environment } from "../../environment/environment";

const Auth: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const history = useHistory();
  const [token, setToken] = useState<string>();
  const [userRequestState, setUserRequestState] = useState<RequestState>(RequestState.INITIAL);
  if (!token) {
    const token = AuthService.getToken();

    if (token) {
      setToken(token);
      history.push("/system-admin/view/instance");

    } else {
      location.href = environment.app_url + "/login";
    }
  }
  
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  };

  useEffect(() => {
    if (token && !user && userRequestState === RequestState.INITIAL) {
      setUserRequestState(RequestState.LOADING);
      AuthService.getMe()
        .then((res) => {
          if (res.success) {
            setUser(res.data);
            setUserRequestState(RequestState.SUCCESS);
          } else {
            setUserRequestState(RequestState.FAILED);
          }
        })
        .catch(() => {
          setUserRequestState(RequestState.FAILED);
        });
    }
  }, [token]);

  switch (userRequestState) {
    case RequestState.FAILED:
      logout();
      return <br />;
    case RequestState.SUCCESS:
      return (
        <div>
          <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>
         
        </div>
      );
    default:
      return (
        <div className="pre-loader">
          {/* <div className="pre-loader-box">
            <div className="loader-progress" id="progress_div">
              <div className="bar" id="bar1"></div>
            </div>
            <div className="percent" id="percent1">
              0%
            </div>
            <div className="loading-text ">Loading...</div>
          </div> */}
        </div>
      );
  }
};

export default Auth;
