import React, { useEffect, useState } from "react";
import "../assets/css/style1.css";
import "../assets/css/core.css";
import { AdminService } from "../../servises/AdminService";
import { InstanceValue } from "../../models/NoteValues";
import { Col, } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { environment } from "../../environment/environment";
import Swal from "sweetalert2";

const ViewNotes: React.FC = () => {
    const [list, setList] = useState<InstanceValue[]>([] as InstanceValue[]);
    useEffect(() => {
        allInstances();
    }, []);

    const allInstances = () => {
        AdminService.getAllInstances().then((res) => {
            setList(res.data);
        });
    }
    const addInstance = () => {
        AdminService.AddInstance().then(res => {
            if (res.success) {
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                allInstances();
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    };

    const deleteInstance = (value) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure want to remove this instance?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                AdminService.DeleteInstance(value).then(res => {
                    if (res.success) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        allInstances();
                    }
                    else {
                        toast.error(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
            }
        });
    }

    const clearData = (value) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure want to clear this instance?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                AdminService.ClearInstance(value).then(res => {
                    if (res.success) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                    else {
                        toast.error(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
            }
        });
    }




    return (
        <div className="main-container0001">
            <div className="pd-ltr-20">
                <div className="rowCheck pb-4 pt-4 height-100-p">
                    <button onClick={addInstance} className="btn btn-primary btn-sm adminBtn float-right action_btnNew">
                        Add New Instance
                    </button>
                </div>


                <div className="card-box pd-20 height-100-p mb-30 mt-20">
                    <div className="row">
                        <div className="title mb-15  col-md-6 col-sm-12 mb-20 ">
                            <h4 className="cardHearder">View All Instances</h4>
                        </div>
                        <div className="row">
                            <Col sm="12" className="mt10">
                                <div className="table-responsive">
                                    {list !== undefined ? (
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Unic Id</th>
                                                    <th scope="col">Copy Link</th>
                                                    <th scope="col">Clear Table</th>
                                                    <th scope="col">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list &&
                                                    list.map((instance, index) => (
                                                        <tr key={instance._id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td scope="row">{instance.unicId}</td>
                                                            <td scope="row">    <button
                                                                className="btn btn-sm btn-primary"
                                                                style={{ width: "120px" }}
                                                                onClick={() => { navigator.clipboard.writeText(environment.app_url + "/notes" + "/" + instance.unicId) }}
                                                            >
                                                                <i className="dw dw-copy"></i>  Copy Link
                                                            </button>
                                                            </td>
                                                            <td scope="row">
                                                                <button
                                                                    className="btn btn-sm btn-dark"
                                                                    style={{ width: "120px" }}
                                                                    onClick={() => {
                                                                        clearData(instance?.unicId);
                                                                    }}
                                                                >
                                                                    <i className="ti ti-brush-alt"></i>  Clear Table
                                                                </button>
                                                            </td>
                                                            <td scope="row">
                                                                <div className="d-flex p-0">
                                                                    <div className="px-2">
                                                                        <button
                                                                            className="btn btn-sm btn-danger"
                                                                            style={{ width: "110px" }}
                                                                            onClick={() => {
                                                                                deleteInstance(instance?.unicId);
                                                                            }}
                                                                        >
                                                                            <i className="dw dw-trash"></i> Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <>
                                            <div>No Instances</div>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default ViewNotes;
