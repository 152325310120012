import React, { useEffect, useState } from "react";
import { AuthService } from "../../servises/AuthService";
import { useHistory } from "react-router-dom";
import { RequestState } from "../../RequestState";
import { User } from "../../models/User";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import './../assets/css/style.css';
import "./../assets/css/core.css";
import "./../assets/css/media.css";

const Login: React.FC = () => {
    const history = useHistory();
    const token = AuthService.getToken();
    const initialState = { email: "", password: "" };
    const [userData, setUserData] = useState(initialState);
    const [loginRequestState, setLoginRequestState] = useState<RequestState>(RequestState.INITIAL);



    useEffect(() => {
        if (loginRequestState === RequestState.LOADING) {
            AuthService.userLogin(userData)
                .then((res) => {
                    if (res.success) {
                        AuthService.setToken(res.data);
                        setLoginRequestState(RequestState.SUCCESS);
                    } else {
                        setLoginRequestState(RequestState.FAILED);
                        toast.error("", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
                .catch(() => {
                    setLoginRequestState(RequestState.FAILED);
                });
        }

    }, [loginRequestState]);

    const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
        if (!userData.email) {
            toast.error("Please enter user email!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
        if (!userData.password) {
            toast.error("Please enter user password!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
        event.preventDefault();
        setLoginRequestState(RequestState.LOADING);
    };

    if (token || loginRequestState === RequestState.SUCCESS) {
        AuthService.getMe().then((res) => {
            if (res.success) {
                const loggedUser = res.data as User;
                history.push("/system-admin/view/instance");
            }
        });
    }


    return (
        <div className="container0001">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={submitLogin} className="formPadding">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fa fa-user"></i></span>
                            </div>
                            <input type="text" className="form-control" placeholder="Email" onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
                        </div>
                        <div className="input-group mb-6">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fa fa-lock"></i></span>
                            </div>
                            <input type="password" className="form-control" placeholder="Password" onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
                        </div>
                        <button type="submit" className="btn btn btn-sm adminBtn btn-block" >Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
