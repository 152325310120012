import React, { useContext, useState, useEffect } from "react";
import { MenuContext } from "../context/MenuContext";
import { useHistory } from "react-router-dom";
import UserContext from "../context/UserContext";
import "../assets/css/style1.css";
import "../assets/css/core.css";
import Swal from "sweetalert2";
import "../assets/css/icon-font.css";

const NavBarDashbord: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const [user] = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure want to logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
      }
    });
  };


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };




  return (
    <div className="header">
      <div className="header-left">
        <div className="menu-icon ti ti-menu-alt" onClick={toggleMenu}></div>


      </div>
      <div className="header-right">
        <span className="marginLogout" onClick={logout}>
          <i className="dw dw-logout"></i> Log Out
        </span>
      </div>
    </div>
  );
};

export default NavBarDashbord;
