import React, { useState, useEffect } from "react";
import './index.css';
import { useParams } from "react-router-dom";
import { Col, Row, Modal, CardBody, Input, Card, OffcanvasHeader } from "reactstrap";
import { InstanceValue, NoteValues } from "../../models/NoteValues";
import { CommonService } from "../../servises/CommonService";
import { ParamTypes } from "../util/paramTypes";
import BounceLoader from "react-spinners/BounceLoader";

const HomePage: React.FC = () => {
  const { unicId } = useParams<ParamTypes>();
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    CommonService.getAllRows(unicId).then(res => {
      res.data?.rows.map(item => {
        if (item.isRow == "bakers") {
          setToggle(item.value)
        }
        if (item.isRow == "busser") {
          setToggle1(item.value)
        }
        if (item.isRow == "clown") {
          setToggle2(item.value)
        }
        if (item.isRow == "conspiracist") {
          setToggle3(item.value)
        }
        if (item.isRow == "fanatic") {
          setToggle4(item.value)
        }
        if (item.isRow == "foodCritic") {
          setToggle5(item.value)
        }
        if (item.isRow == "governmentAgent") {
          setToggle6(item.value)
        }
        if (item.isRow == "healthInspector") {
          setToggle7(item.value)
        }
        if (item.isRow == "impersonator") {
          setToggle8(item.value)
        }
        if (item.isRow == "innocentPatron") {
          setToggle9(item.value)
        }
        if (item.isRow == "intern") {
          setToggle11(item.value)
        }
        if (item.isRow == "neighbor") {
          setToggle12(item.value)
        }
        if (item.isRow == "niece") {
          setToggle13(item.value)
        }
        if (item.isRow == "protestor") {
          setToggle14(item.value)
        }
        if (item.isRow == "rival") {
          setToggle15(item.value)
        }
        if (item.isRow == "salesperson") {
          setToggle16(item.value)
        }
        if (item.isRow == "spouse") {
          setToggle17(item.value)
        }
        if (item.isRow == "spouseSpouse") {
          setToggle18(item.value)
        }
        if (item.isRow == "suspiciousPatron") {
          setToggle19(item.value)
        }
        if (item.isRow == "teacher") {
          setToggle21(item.value)
        }
        if (item.isRow == "tourGuide") {
          setToggle22(item.value)
        }
        if (item.isRow == "tourist") {
          setToggle23(item.value)
        }
      })
    })

    CommonService.getAllNotes(unicId).then(res => {
      let copy = { ...bakers }
      let copy1 = { ...busser }
      let copy2 = { ...clown }
      let copy3 = { ...conspiracist }
      let copy4 = { ...fanatic }
      let copy5 = { ...foodCritic }
      let copy6 = { ...governmentAgent }
      let copy7 = { ...healthInspector }
      let copy8 = { ...impersonator }
      let copy9 = { ...innocentPatron }
      let copy10 = { ...intern }
      let copy11 = { ...neighbor }
      let copy12 = { ...niece }
      let copy13 = { ...protestor }
      let copy14 = { ...rival }
      let copy15 = { ...salesperson }
      let copy16 = { ...spouse }
      let copy17 = { ...spouseSpouse }
      let copy18 = { ...suspiciousPatron }
      let copy19 = { ...teacher }
      let copy20 = { ...tourGuide }
      let copy21 = { ...tourist }

      setInstanceId({ ...instanceId, _id: res.data?.instance?._id, unicId: res.data?.instance?.unicId })
      setAllNotes({ ...allNotes, allNotes: res.data?.instance?.allNotes })
      setIsLoaded(true);
      res.data?.notes.map(item => {

        if (item.cell_name == "1_1") {
          copy = { ...copy, allergen: item?.value }
        } else if (item.cell_name == "1_2") {
          copy = { ...copy, axe: item?.value }
        } else if (item.cell_name == "1_3") {
          copy = { ...copy, breadstick: item?.value }
        } else if (item.cell_name == "1_4") {
          copy = { ...copy, gun: item?.value }
        } else if (item.cell_name == "1_5") {
          copy = { ...copy, poison: item?.value }
        } else if (item.cell_name == "1_6") {
          copy = { ...copy, bathroom: item?.value }
        } else if (item.cell_name == "1_7") {
          copy = { ...copy, chambers: item?.value }
        } else if (item.cell_name == "1_8") {
          copy = { ...copy, kitchen: item?.value }
        } else if (item.cell_name == "1_9") {
          copy = { ...copy, diningFloor: item?.value }
        } else if (item.cell_name == "1_10") {
          copy = { ...copy, dumpster: item?.value }
        } else if (item.cell_name == "1_11") {
          copy = { ...copy, otherNotes: item?.value }
        }

        if (item.cell_name == "2_1") {
          copy1 = { ...copy1, allergen: item?.value }
        } else if (item.cell_name == "2_2") {
          copy1 = { ...copy1, axe: item?.value }
        } else if (item.cell_name == "2_3") {
          copy1 = { ...copy1, breadstick: item?.value }
        } else if (item.cell_name == "2_4") {
          copy1 = { ...copy1, gun: item?.value }
        } else if (item.cell_name == "2_5") {
          copy1 = { ...copy1, poison: item?.value }
        } else if (item.cell_name == "2_6") {
          copy1 = { ...copy1, bathroom: item?.value }
        } else if (item.cell_name == "2_7") {
          copy1 = { ...copy1, chambers: item?.value }
        } else if (item.cell_name == "2_8") {
          copy1 = { ...copy1, kitchen: item?.value }
        } else if (item.cell_name == "2_9") {
          copy1 = { ...copy1, diningFloor: item?.value }
        } else if (item.cell_name == "2_10") {
          copy1 = { ...copy1, dumpster: item?.value }
        } else if (item.cell_name == "2_11") {
          copy1 = { ...copy1, otherNotes: item?.value }
        }

        if (item.cell_name == "3_1") {
          copy2 = { ...copy2, allergen: item?.value }
        } else if (item.cell_name == "3_2") {
          copy2 = { ...copy2, axe: item?.value }
        } else if (item.cell_name == "3_3") {
          copy2 = { ...copy2, breadstick: item?.value }
        } else if (item.cell_name == "3_4") {
          copy2 = { ...copy2, gun: item?.value }
        } else if (item.cell_name == "3_5") {
          copy2 = { ...copy2, poison: item?.value }
        } else if (item.cell_name == "3_6") {
          copy2 = { ...copy2, bathroom: item?.value }
        } else if (item.cell_name == "3_7") {
          copy2 = { ...copy2, chambers: item?.value }
        } else if (item.cell_name == "3_8") {
          copy2 = { ...copy2, kitchen: item?.value }
        } else if (item.cell_name == "3_9") {
          copy2 = { ...copy2, diningFloor: item?.value }
        } else if (item.cell_name == "3_10") {
          copy2 = { ...copy2, dumpster: item?.value }
        } else if (item.cell_name == "3_11") {
          copy2 = { ...copy2, otherNotes: item?.value }
        }

        if (item.cell_name == "4_1") {
          copy3 = { ...copy3, allergen: item?.value }
        } else if (item.cell_name == "4_2") {
          copy3 = { ...copy3, axe: item?.value }
        } else if (item.cell_name == "4_3") {
          copy3 = { ...copy3, breadstick: item?.value }
        } else if (item.cell_name == "4_4") {
          copy3 = { ...copy3, gun: item?.value }
        } else if (item.cell_name == "4_5") {
          copy3 = { ...copy3, poison: item?.value }
        } else if (item.cell_name == "4_6") {
          copy3 = { ...copy3, bathroom: item?.value }
        } else if (item.cell_name == "4_7") {
          copy3 = { ...copy3, chambers: item?.value }
        } else if (item.cell_name == "4_8") {
          copy3 = { ...copy3, kitchen: item?.value }
        } else if (item.cell_name == "4_9") {
          copy3 = { ...copy3, diningFloor: item?.value }
        } else if (item.cell_name == "4_10") {
          copy3 = { ...copy3, dumpster: item?.value }
        } else if (item.cell_name == "4_11") {
          copy3 = { ...copy3, otherNotes: item?.value }
        }

        if (item.cell_name == "5_1") {
          copy4 = { ...copy4, allergen: item?.value }
        } else if (item.cell_name == "5_2") {
          copy4 = { ...copy4, axe: item?.value }
        } else if (item.cell_name == "5_3") {
          copy4 = { ...copy4, breadstick: item?.value }
        } else if (item.cell_name == "5_4") {
          copy4 = { ...copy4, gun: item?.value }
        } else if (item.cell_name == "5_5") {
          copy4 = { ...copy4, poison: item?.value }
        } else if (item.cell_name == "5_6") {
          copy4 = { ...copy4, bathroom: item?.value }
        } else if (item.cell_name == "5_7") {
          copy4 = { ...copy4, chambers: item?.value }
        } else if (item.cell_name == "5_8") {
          copy4 = { ...copy4, kitchen: item?.value }
        } else if (item.cell_name == "5_9") {
          copy4 = { ...copy4, diningFloor: item?.value }
        } else if (item.cell_name == "5_10") {
          copy4 = { ...copy4, dumpster: item?.value }
        } else if (item.cell_name == "5_11") {
          copy4 = { ...copy4, otherNotes: item?.value }
        }

        if (item.cell_name == "6_1") {
          copy5 = { ...copy5, allergen: item?.value }
        } else if (item.cell_name == "6_2") {
          copy5 = { ...copy5, axe: item?.value }
        } else if (item.cell_name == "6_3") {
          copy5 = { ...copy5, breadstick: item?.value }
        } else if (item.cell_name == "6_4") {
          copy5 = { ...copy5, gun: item?.value }
        } else if (item.cell_name == "6_5") {
          copy5 = { ...copy5, poison: item?.value }
        } else if (item.cell_name == "6_6") {
          copy5 = { ...copy5, bathroom: item?.value }
        } else if (item.cell_name == "6_7") {
          copy5 = { ...copy5, chambers: item?.value }
        } else if (item.cell_name == "6_8") {
          copy5 = { ...copy5, kitchen: item?.value }
        } else if (item.cell_name == "6_9") {
          copy5 = { ...copy5, diningFloor: item?.value }
        } else if (item.cell_name == "6_10") {
          copy5 = { ...copy5, dumpster: item?.value }
        } else if (item.cell_name == "6_11") {
          copy5 = { ...copy5, otherNotes: item?.value }
        }

        if (item.cell_name == "7_1") {
          copy6 = { ...copy6, allergen: item?.value }
        } else if (item.cell_name == "7_2") {
          copy6 = { ...copy6, axe: item?.value }
        } else if (item.cell_name == "7_3") {
          copy6 = { ...copy6, breadstick: item?.value }
        } else if (item.cell_name == "7_4") {
          copy6 = { ...copy6, gun: item?.value }
        } else if (item.cell_name == "7_5") {
          copy6 = { ...copy6, poison: item?.value }
        } else if (item.cell_name == "7_6") {
          copy6 = { ...copy6, bathroom: item?.value }
        } else if (item.cell_name == "7_7") {
          copy6 = { ...copy6, chambers: item?.value }
        } else if (item.cell_name == "7_8") {
          copy6 = { ...copy6, kitchen: item?.value }
        } else if (item.cell_name == "7_9") {
          copy6 = { ...copy6, diningFloor: item?.value }
        } else if (item.cell_name == "7_10") {
          copy6 = { ...copy6, dumpster: item?.value }
        } else if (item.cell_name == "7_11") {
          copy6 = { ...copy6, otherNotes: item?.value }
        }

        if (item.cell_name == "8_1") {
          copy7 = { ...copy7, allergen: item?.value }
        } else if (item.cell_name == "8_2") {
          copy7 = { ...copy7, axe: item?.value }
        } else if (item.cell_name == "8_3") {
          copy7 = { ...copy7, breadstick: item?.value }
        } else if (item.cell_name == "8_4") {
          copy7 = { ...copy7, gun: item?.value }
        } else if (item.cell_name == "8_5") {
          copy7 = { ...copy7, poison: item?.value }
        } else if (item.cell_name == "8_6") {
          copy7 = { ...copy7, bathroom: item?.value }
        } else if (item.cell_name == "8_7") {
          copy7 = { ...copy7, chambers: item?.value }
        } else if (item.cell_name == "8_8") {
          copy7 = { ...copy7, kitchen: item?.value }
        } else if (item.cell_name == "8_9") {
          copy7 = { ...copy7, diningFloor: item?.value }
        } else if (item.cell_name == "8_10") {
          copy7 = { ...copy7, dumpster: item?.value }
        } else if (item.cell_name == "8_11") {
          copy7 = { ...copy7, otherNotes: item?.value }
        }


        if (item.cell_name == "9_1") {
          copy8 = { ...copy8, allergen: item?.value }
        } else if (item.cell_name == "9_2") {
          copy8 = { ...copy8, axe: item?.value }
        } else if (item.cell_name == "9_3") {
          copy8 = { ...copy8, breadstick: item?.value }
        } else if (item.cell_name == "9_4") {
          copy8 = { ...copy8, gun: item?.value }
        } else if (item.cell_name == "9_5") {
          copy8 = { ...copy8, poison: item?.value }
        } else if (item.cell_name == "9_6") {
          copy8 = { ...copy8, bathroom: item?.value }
        } else if (item.cell_name == "9_7") {
          copy8 = { ...copy8, chambers: item?.value }
        } else if (item.cell_name == "9_8") {
          copy8 = { ...copy8, kitchen: item?.value }
        } else if (item.cell_name == "9_9") {
          copy8 = { ...copy8, diningFloor: item?.value }
        } else if (item.cell_name == "9_10") {
          copy8 = { ...copy8, dumpster: item?.value }
        }else if (item.cell_name == "9_11") {
          copy8 = { ...copy8, otherNotes: item?.value }
        }

        if (item.cell_name == "10_1") {
          copy9 = { ...copy9, allergen: item?.value }
        } else if (item.cell_name == "10_2") {
          copy9 = { ...copy9, axe: item?.value }
        } else if (item.cell_name == "10_3") {
          copy9 = { ...copy9, breadstick: item?.value }
        } else if (item.cell_name == "10_4") {
          copy9 = { ...copy9, gun: item?.value }
        } else if (item.cell_name == "10_5") {
          copy9 = { ...copy9, poison: item?.value }
        } else if (item.cell_name == "10_6") {
          copy9 = { ...copy9, bathroom: item?.value }
        } else if (item.cell_name == "10_7") {
          copy9 = { ...copy9, chambers: item?.value }
        } else if (item.cell_name == "10_8") {
          copy9 = { ...copy9, kitchen: item?.value }
        } else if (item.cell_name == "10_9") {
          copy9 = { ...copy9, diningFloor: item?.value }
        } else if (item.cell_name == "10_10") {
          copy9 = { ...copy9, dumpster: item?.value }
        } else if (item.cell_name == "10_11") {
          copy9 = { ...copy9, otherNotes: item?.value }
        }

        if (item.cell_name == "11_1") {
          copy10 = { ...copy10, allergen: item?.value }
        } else if (item.cell_name == "11_2") {
          copy10 = { ...copy10, axe: item?.value }
        } else if (item.cell_name == "11_3") {
          copy10 = { ...copy10, breadstick: item?.value }
        } else if (item.cell_name == "11_4") {
          copy10 = { ...copy10, gun: item?.value }
        } else if (item.cell_name == "11_5") {
          copy10 = { ...copy10, poison: item?.value }
        } else if (item.cell_name == "11_6") {
          copy10 = { ...copy10, bathroom: item?.value }
        } else if (item.cell_name == "11_7") {
          copy10 = { ...copy10, chambers: item?.value }
        } else if (item.cell_name == "11_8") {
          copy10 = { ...copy10, kitchen: item?.value }
        } else if (item.cell_name == "11_9") {
          copy10 = { ...copy10, diningFloor: item?.value }
        } else if (item.cell_name == "11_10") {
          copy10 = { ...copy10, dumpster: item?.value }
        }else if (item.cell_name == "11_11") {
          copy10 = { ...copy10, otherNotes: item?.value }
        }

        if (item.cell_name == "12_1") {
          copy11 = { ...copy11, allergen: item?.value }
        } else if (item.cell_name == "12_2") {
          copy11 = { ...copy11, axe: item?.value }
        } else if (item.cell_name == "12_3") {
          copy11 = { ...copy11, breadstick: item?.value }
        } else if (item.cell_name == "12_4") {
          copy11 = { ...copy11, gun: item?.value }
        } else if (item.cell_name == "12_5") {
          copy11 = { ...copy11, poison: item?.value }
        } else if (item.cell_name == "12_6") {
          copy11 = { ...copy11, bathroom: item?.value }
        } else if (item.cell_name == "12_7") {
          copy11 = { ...copy11, chambers: item?.value }
        } else if (item.cell_name == "12_8") {
          copy11 = { ...copy11, kitchen: item?.value }
        } else if (item.cell_name == "12_9") {
          copy11 = { ...copy11, diningFloor: item?.value }
        } else if (item.cell_name == "12_10") {
          copy11 = { ...copy11, dumpster: item?.value }
        }else if (item.cell_name == "12_11") {
          copy11 = { ...copy11, otherNotes: item?.value }
        }

        if (item.cell_name == "13_1") {
          copy12 = { ...copy12, allergen: item?.value }
        } else if (item.cell_name == "13_2") {
          copy12 = { ...copy12, axe: item?.value }
        } else if (item.cell_name == "13_3") {
          copy12 = { ...copy12, breadstick: item?.value }
        } else if (item.cell_name == "13_4") {
          copy12 = { ...copy12, gun: item?.value }
        } else if (item.cell_name == "13_5") {
          copy12 = { ...copy12, poison: item?.value }
        } else if (item.cell_name == "13_6") {
          copy12 = { ...copy12, bathroom: item?.value }
        } else if (item.cell_name == "13_7") {
          copy12 = { ...copy12, chambers: item?.value }
        } else if (item.cell_name == "13_8") {
          copy12 = { ...copy12, kitchen: item?.value }
        } else if (item.cell_name == "13_9") {
          copy12 = { ...copy12, diningFloor: item?.value }
        } else if (item.cell_name == "13_10") {
          copy12 = { ...copy12, dumpster: item?.value }
        }else if (item.cell_name == "13_11") {
          copy12 = { ...copy12, otherNotes: item?.value }
        }

        if (item.cell_name == "14_1") {
          copy13 = { ...copy13, allergen: item?.value }
        } else if (item.cell_name == "14_2") {
          copy13 = { ...copy13, axe: item?.value }
        } else if (item.cell_name == "14_3") {
          copy13 = { ...copy13, breadstick: item?.value }
        } else if (item.cell_name == "14_4") {
          copy13 = { ...copy13, gun: item?.value }
        } else if (item.cell_name == "14_5") {
          copy13 = { ...copy13, poison: item?.value }
        } else if (item.cell_name == "14_6") {
          copy13 = { ...copy13, bathroom: item?.value }
        } else if (item.cell_name == "14_7") {
          copy13 = { ...copy13, chambers: item?.value }
        } else if (item.cell_name == "14_8") {
          copy13 = { ...copy13, kitchen: item?.value }
        } else if (item.cell_name == "14_9") {
          copy13 = { ...copy13, diningFloor: item?.value }
        } else if (item.cell_name == "14_10") {
          copy13 = { ...copy13, dumpster: item?.value }
        } else if (item.cell_name == "14_11") {
          copy13 = { ...copy13, otherNotes: item?.value }
        }

        if (item.cell_name == "15_1") {
          copy14 = { ...copy14, allergen: item?.value }
        } else if (item.cell_name == "15_2") {
          copy14 = { ...copy14, axe: item?.value }
        } else if (item.cell_name == "15_3") {
          copy14 = { ...copy14, breadstick: item?.value }
        } else if (item.cell_name == "15_4") {
          copy14 = { ...copy14, gun: item?.value }
        } else if (item.cell_name == "15_5") {
          copy14 = { ...copy14, poison: item?.value }
        } else if (item.cell_name == "15_6") {
          copy14 = { ...copy14, bathroom: item?.value }
        } else if (item.cell_name == "15_7") {
          copy14 = { ...copy14, chambers: item?.value }
        } else if (item.cell_name == "15_8") {
          copy14 = { ...copy14, kitchen: item?.value }
        } else if (item.cell_name == "15_9") {
          copy14 = { ...copy14, diningFloor: item?.value }
        } else if (item.cell_name == "15_10") {
          copy14 = { ...copy14, dumpster: item?.value }
        } else if (item.cell_name == "15_11") {
          copy14 = { ...copy14, otherNotes: item?.value }
        }

        if (item.cell_name == "16_1") {
          copy15 = { ...copy15, allergen: item?.value }
        } else if (item.cell_name == "16_2") {
          copy15 = { ...copy15, axe: item?.value }
        } else if (item.cell_name == "16_3") {
          copy15 = { ...copy15, breadstick: item?.value }
        } else if (item.cell_name == "16_4") {
          copy15 = { ...copy15, gun: item?.value }
        } else if (item.cell_name == "16_5") {
          copy15 = { ...copy15, poison: item?.value }
        } else if (item.cell_name == "16_6") {
          copy15 = { ...copy15, bathroom: item?.value }
        } else if (item.cell_name == "16_7") {
          copy15 = { ...copy15, chambers: item?.value }
        } else if (item.cell_name == "16_8") {
          copy15 = { ...copy15, kitchen: item?.value }
        } else if (item.cell_name == "16_9") {
          copy15 = { ...copy15, diningFloor: item?.value }
        } else if (item.cell_name == "16_10") {
          copy15 = { ...copy15, dumpster: item?.value }
        }else if (item.cell_name == "16_11") {
          copy15 = { ...copy15, otherNotes: item?.value }
        }

        if (item.cell_name == "17_1") {
          copy16 = { ...copy16, allergen: item?.value }
        } else if (item.cell_name == "17_2") {
          copy16 = { ...copy16, axe: item?.value }
        } else if (item.cell_name == "17_3") {
          copy16 = { ...copy16, breadstick: item?.value }
        } else if (item.cell_name == "17_4") {
          copy16 = { ...copy16, gun: item?.value }
        } else if (item.cell_name == "17_5") {
          copy16 = { ...copy16, poison: item?.value }
        } else if (item.cell_name == "17_6") {
          copy16 = { ...copy16, bathroom: item?.value }
        } else if (item.cell_name == "17_7") {
          copy16 = { ...copy16, chambers: item?.value }
        } else if (item.cell_name == "17_8") {
          copy16 = { ...copy16, kitchen: item?.value }
        } else if (item.cell_name == "17_9") {
          copy16 = { ...copy16, diningFloor: item?.value }
        } else if (item.cell_name == "17_10") {
          copy16 = { ...copy16, dumpster: item?.value }
        } else if (item.cell_name == "17_11") {
          copy16 = { ...copy16, otherNotes: item?.value }
        }


        if (item.cell_name == "18_1") {
          copy17 = { ...copy17, allergen: item?.value }
        } else if (item.cell_name == "18_2") {
          copy17 = { ...copy17, axe: item?.value }
        } else if (item.cell_name == "18_3") {
          copy17 = { ...copy17, breadstick: item?.value }
        } else if (item.cell_name == "18_4") {
          copy17 = { ...copy17, gun: item?.value }
        } else if (item.cell_name == "18_5") {
          copy17 = { ...copy17, poison: item?.value }
        } else if (item.cell_name == "18_6") {
          copy17 = { ...copy17, bathroom: item?.value }
        } else if (item.cell_name == "18_7") {
          copy17 = { ...copy17, chambers: item?.value }
        } else if (item.cell_name == "18_8") {
          copy17 = { ...copy17, kitchen: item?.value }
        } else if (item.cell_name == "18_9") {
          copy17 = { ...copy17, diningFloor: item?.value }
        } else if (item.cell_name == "18_10") {
          copy17 = { ...copy17, dumpster: item?.value }
        } else if (item.cell_name == "18_11") {
          copy17 = { ...copy17, otherNotes: item?.value }
        }

        if (item.cell_name == "19_1") {
          copy18 = { ...copy18, allergen: item?.value }
        } else if (item.cell_name == "19_2") {
          copy18 = { ...copy18, axe: item?.value }
        } else if (item.cell_name == "19_3") {
          copy18 = { ...copy18, breadstick: item?.value }
        } else if (item.cell_name == "19_4") {
          copy18 = { ...copy18, gun: item?.value }
        } else if (item.cell_name == "19_5") {
          copy18 = { ...copy18, poison: item?.value }
        } else if (item.cell_name == "19_6") {
          copy18 = { ...copy18, bathroom: item?.value }
        } else if (item.cell_name == "19_7") {
          copy18 = { ...copy18, chambers: item?.value }
        } else if (item.cell_name == "19_8") {
          copy18 = { ...copy18, kitchen: item?.value }
        } else if (item.cell_name == "19_9") {
          copy18 = { ...copy18, diningFloor: item?.value }
        } else if (item.cell_name == "19_10") {
          copy18 = { ...copy18, dumpster: item?.value }
        }else if (item.cell_name == "19_11") {
          copy18 = { ...copy18, otherNotes: item?.value }
        }

        if (item.cell_name == "20_1") {
          copy19 = { ...copy19, allergen: item?.value }
        } else if (item.cell_name == "20_2") {
          copy19 = { ...copy19, axe: item?.value }
        } else if (item.cell_name == "20_3") {
          copy19 = { ...copy19, breadstick: item?.value }
        } else if (item.cell_name == "20_4") {
          copy19 = { ...copy19, gun: item?.value }
        } else if (item.cell_name == "20_5") {
          copy19 = { ...copy19, poison: item?.value }
        } else if (item.cell_name == "20_6") {
          copy19 = { ...copy19, bathroom: item?.value }
        } else if (item.cell_name == "20_7") {
          copy19 = { ...copy19, chambers: item?.value }
        } else if (item.cell_name == "20_8") {
          copy19 = { ...copy19, kitchen: item?.value }
        } else if (item.cell_name == "20_9") {
          copy19 = { ...copy19, diningFloor: item?.value }
        } else if (item.cell_name == "20_10") {
          copy19 = { ...copy19, dumpster: item?.value }
        }else if (item.cell_name == "20_11") {
          copy19 = { ...copy19, otherNotes: item?.value }
        }

        if (item.cell_name == "21_1") {
          copy20 = { ...copy20, allergen: item?.value }
        } else if (item.cell_name == "21_2") {
          copy20 = { ...copy20, axe: item?.value }
        } else if (item.cell_name == "21_3") {
          copy20 = { ...copy20, breadstick: item?.value }
        } else if (item.cell_name == "21_4") {
          copy20 = { ...copy20, gun: item?.value }
        } else if (item.cell_name == "21_5") {
          copy20 = { ...copy20, poison: item?.value }
        } else if (item.cell_name == "21_6") {
          copy20 = { ...copy20, bathroom: item?.value }
        } else if (item.cell_name == "21_7") {
          copy20 = { ...copy20, chambers: item?.value }
        } else if (item.cell_name == "21_8") {
          copy20 = { ...copy20, kitchen: item?.value }
        } else if (item.cell_name == "21_9") {
          copy20 = { ...copy20, diningFloor: item?.value }
        } else if (item.cell_name == "21_10") {
          copy20 = { ...copy20, dumpster: item?.value }
        }else if (item.cell_name == "21_11") {
          copy20 = { ...copy20, otherNotes: item?.value }
        }

        if (item.cell_name == "22_1") {
          copy21 = { ...copy21, allergen: item?.value }
        } else if (item.cell_name == "22_2") {
          copy21 = { ...copy21, axe: item?.value }
        } else if (item.cell_name == "22_3") {
          copy21 = { ...copy21, breadstick: item?.value }
        } else if (item.cell_name == "22_4") {
          copy21 = { ...copy21, gun: item?.value }
        } else if (item.cell_name == "22_5") {
          copy21 = { ...copy21, poison: item?.value }
        } else if (item.cell_name == "22_6") {
          copy21 = { ...copy21, bathroom: item?.value }
        } else if (item.cell_name == "22_7") {
          copy21 = { ...copy21, chambers: item?.value }
        } else if (item.cell_name == "22_8") {
          copy21 = { ...copy21, kitchen: item?.value }
        } else if (item.cell_name == "22_9") {
          copy21 = { ...copy21, diningFloor: item?.value }
        } else if (item.cell_name == "22_10") {
          copy21 = { ...copy21, dumpster: item?.value }
        } else if (item.cell_name == "22_11") {
          copy21 = { ...copy21, otherNotes: item?.value }
        }


      });
      setBakers(copy);
      setBusser(copy1);
      setClown(copy2);
      setConspiracist(copy3);
      setFanatic(copy4);
      setFoodCritic(copy5);
      setGovernmentAgent(copy6);
      sethealthInspector(copy7);
      setImpersonator(copy8);
      setInnocentPatron(copy9);
      setIntern(copy10);
      setNeighbor(copy11);
      setNiece(copy12);
      setProtestor(copy13);
      setRival(copy14);
      setSalesperson(copy15);
      setSpouse(copy16);
      setSpouseSpouse(copy17);
      setSuspiciousPatron(copy18);
      setTeacher(copy19);
      setTourGuide(copy20);
      setTourist(copy21);
      setIsLoaded(false);
    });
  }, []);

  const [bakers, setBakers] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [busser, setBusser] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [clown, setClown] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [conspiracist, setConspiracist] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [fanatic, setFanatic] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [foodCritic, setFoodCritic] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [governmentAgent, setGovernmentAgent] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [healthInspector, sethealthInspector] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [impersonator, setImpersonator] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [innocentPatron, setInnocentPatron] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [intern, setIntern] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [neighbor, setNeighbor] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [niece, setNiece] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [protestor, setProtestor] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [rival, setRival] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [salesperson, setSalesperson] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [spouse, setSpouse] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [spouseSpouse, setSpouseSpouse] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [suspiciousPatron, setSuspiciousPatron] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [teacher, setTeacher] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [tourGuide, setTourGuide] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [tourist, setTourist] = useState({
    allergen: "off", axe: "off", breadstick: "off", gun: "off", poison: "off", bathroom: "", chambers: "", kitchen: "", diningFloor: "", dumpster: "", otherNotes: ""
  })

  const [instanceId, setInstanceId] = useState({} as InstanceValue);

  const [allNotes, setAllNotes] = useState({
    allNotes: ""
  })

  const [toggle, setToggle] = useState(true)
  const [toggle1, setToggle1] = useState(true)
  const [toggle2, setToggle2] = useState(true)
  const [toggle3, setToggle3] = useState(true)
  const [toggle4, setToggle4] = useState(true)
  const [toggle5, setToggle5] = useState(true)
  const [toggle6, setToggle6] = useState(true)
  const [toggle7, setToggle7] = useState(true)
  const [toggle8, setToggle8] = useState(true)
  const [toggle9, setToggle9] = useState(true)
  const [toggle11, setToggle11] = useState(true)
  const [toggle12, setToggle12] = useState(true)
  const [toggle13, setToggle13] = useState(true)
  const [toggle14, setToggle14] = useState(true)
  const [toggle15, setToggle15] = useState(true)
  const [toggle16, setToggle16] = useState(true)
  const [toggle17, setToggle17] = useState(true)
  const [toggle18, setToggle18] = useState(true)
  const [toggle19, setToggle19] = useState(true)
  const [toggle21, setToggle21] = useState(true)
  const [toggle22, setToggle22] = useState(true)
  const [toggle23, setToggle23] = useState(true)

  const updateTherapistDetails = (value: any, cell: any, setType: any, valueType: any, cellName: any) => {
    { value == "yes" && cell == "allergen" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setBakers" && valueType == "bakers" && setBakers({ ...bakers, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setBusser" && valueType == "busser" && setBusser({ ...busser, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setClown" && valueType == "clown" && setClown({ ...clown, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setConspiracist" && valueType == "conspiracist" && setConspiracist({ ...conspiracist, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setFanatic" && valueType == "fanatic" && setFanatic({ ...fanatic, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setFoodCritic" && valueType == "foodCritic" && setFoodCritic({ ...foodCritic, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setGovernmentAgent" && valueType == "governmentAgent" && setGovernmentAgent({ ...governmentAgent, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "sethealthInspector" && valueType == "healthInspector" && sethealthInspector({ ...healthInspector, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setImpersonator" && valueType == "impersonator" && setImpersonator({ ...impersonator, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setInnocentPatron" && valueType == "innocentPatron" && setInnocentPatron({ ...innocentPatron, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setIntern" && valueType == "intern" && setIntern({ ...intern, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setNeighbor" && valueType == "neighbor" && setNeighbor({ ...neighbor, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setNiece" && valueType == "niece" && setNiece({ ...niece, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setProtestor" && valueType == "protestor" && setProtestor({ ...protestor, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setRival" && valueType == "rival" && setRival({ ...rival, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setSalesperson" && valueType == "salesperson" && setSalesperson({ ...salesperson, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setSpouse" && valueType == "spouse" && setSpouse({ ...spouse, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setSpouseSpouse" && valueType == "spouseSpouse" && setSpouseSpouse({ ...spouseSpouse, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setSuspiciousPatron" && valueType == "suspiciousPatron" && setSuspiciousPatron({ ...suspiciousPatron, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setTeacher" && valueType == "teacher" && setTeacher({ ...teacher, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setTourGuide" && valueType == "tourGuide" && setTourGuide({ ...tourGuide, breadstick: "off" }) }

    { value == "yes" && cell == "allergen" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, allergen: "yes" }) }
    { value == "no" && cell == "allergen" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, allergen: "no" }) }
    { value == "off" && cell == "allergen" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, allergen: "off" }) }
    { value == "yes" && cell == "axe" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, axe: "yes" }) }
    { value == "no" && cell == "axe" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, axe: "no" }) }
    { value == "off" && cell == "axe" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, axe: "off" }) }
    { value == "yes" && cell == "gun" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, gun: "yes" }) }
    { value == "no" && cell == "gun" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, gun: "no" }) }
    { value == "off" && cell == "gun" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, gun: "off" }) }
    { value == "yes" && cell == "poison" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, poison: "yes" }) }
    { value == "no" && cell == "poison" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, poison: "no" }) }
    { value == "off" && cell == "poison" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, poison: "off" }) }
    { value == "yes" && cell == "breadstick" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, breadstick: "yes" }) }
    { value == "no" && cell == "breadstick" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, breadstick: "no" }) }
    { value == "off" && cell == "breadstick" && setType == "setTourist" && valueType == "tourist" && setTourist({ ...tourist, breadstick: "off" }) }

    const updatedUser: any = {
      cell_name: cellName,
      type: "CheckBox",
      value: value,
      instanceId: instanceId?._id,

    };
    CommonService.saveNote(updatedUser, unicId).then(res => {
      if (res.success) {
        console.log(res.data)
      }
    });
  };

  const updateOnchangeDetails = (value: any, cellName: any) => {
    const updatedUser: any = {
      cell_name: cellName,
      type: "Text",
      value: value,
      instanceId: instanceId?._id,

    };
    CommonService.saveNote(updatedUser, unicId).then(res => {
      if (res.success) {
        console.log(res.data)
      }
    });
  };

  const updateInstanceNote = (value: any) => {
    const updatedUser: any = {
      instanceId: instanceId?._id,
      allNotes: value,

    };
    CommonService.addInstanceNote(updatedUser).then(res => {
      if (res.success) {
        console.log(res.data)
      }
    });
  };

  const setToggleValue = (value: boolean, row: any,) => {
    { value == value && row == "bakers" && setToggle(value) }
    { value == value && row == "busser" && setToggle1(value) }
    { value == value && row == "clown" && setToggle2(value) }
    { value == value && row == "conspiracist" && setToggle3(value) }
    { value == value && row == "fanatic" && setToggle4(value) }
    { value == value && row == "foodCritic" && setToggle5(value) }
    { value == value && row == "governmentAgent" && setToggle6(value) }
    { value == value && row == "healthInspector" && setToggle7(value) }
    { value == value && row == "impersonator" && setToggle8(value) }
    { value == value && row == "innocentPatron" && setToggle9(value) }
    { value == value && row == "intern" && setToggle11(value) }
    { value == value && row == "neighbor" && setToggle12(value) }
    { value == value && row == "niece" && setToggle13(value) }
    { value == value && row == "protestor" && setToggle14(value) }
    { value == value && row == "rival" && setToggle15(value) }
    { value == value && row == "salesperson" && setToggle16(value) }
    { value == value && row == "spouse" && setToggle17(value) }
    { value == value && row == "spouseSpouse" && setToggle18(value) }
    { value == value && row == "suspiciousPatron" && setToggle19(value) }
    { value == value && row == "teacher" && setToggle21(value) }
    { value == value && row == "tourGuide" && setToggle22(value) }
    { value == value && row == "tourist" && setToggle23(value) }

    const updatedUser: any = {
      value: value,
      isRow: row
    };

    CommonService.saveRow(updatedUser, unicId).then(res => {
      if (res.success) {
        console.log(res.data)
      }
    });
  }


  return (

    < >
      {unicId === instanceId?.unicId ? (
        <>
          <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
            Suspect Notes
          </h3>
          <div className="row col-md-12" >
            <div className="col-md-8">
              <div className="card-body">
                <div className="table-responsive">

                  <table className="table table-bordered table-responsive-md  text-center">
                    <thead >
                      <tr>
                        <th className="asks"></th>
                        <th className="table-active">Suspect</th>
                        <th className="table-active" colSpan={5}>Weapons</th>
                        <th className="table-active" colSpan={5}>Location</th>
                        <th className="table-active" >Other Notes</th>
                      </tr>
                      <tr>
                        <td className="asks"></td>
                        <td className="table-active"></td>
                        <td className="table-active">Allergen</td>
                        <td className="table-active">Axe</td>
                        <td className="table-active">Breadstick</td>
                        <td className="table-active">Gun</td>
                        <td className="table-active">Poison</td>
                        <td className="table-active">Bathroom</td>
                        <td className="table-active">Chambers</td>
                        <td className="table-active">Kitchen</td>
                        <td className="table-active">Restaurant Floor</td>
                        <td className="table-active">Dumpster</td>
                        <td className="table-active"></td>

                
                      </tr>

                    </thead>

                    <tbody className="asktable">
                      <tr>
                        <td className="minus">{toggle ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "bakers")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "bakers")}></i>}</td>
                        {toggle && (
                          <>
                            <td>Baker</td>
                            <td>
                              {bakers.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setBakers", "bakers", "1_1")
                              }} ><div className="round"></div></div>}

                              {bakers.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setBakers", "bakers", "1_1")
                              }} >
                                <div className="circle">
                                  <div className="checkmark"></div>
                                </div>
                              </div>}

                              {bakers.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setBakers", "bakers", "1_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {bakers.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setBakers", "bakers", "1_2")
                              }} ><div className="round"></div></div>}

                              {bakers.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setBakers", "bakers", "1_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {bakers.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setBakers", "bakers", "1_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {bakers.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setBakers", "bakers", "1_3")
                              }} ><div className="round"></div></div>}

                              {bakers.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setBakers", "bakers", "1_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {bakers.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setBakers", "bakers", "1_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {bakers.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setBakers", "bakers", "1_4")
                              }} ><div className="round"></div></div>}

                              {bakers.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setBakers", "bakers", "1_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {bakers.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setBakers", "bakers", "1_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {bakers.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setBakers", "bakers", "1_5")
                              }} ><div className="round"></div></div>}

                              {bakers.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setBakers", "bakers", "1_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {bakers.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setBakers", "bakers", "1_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                         
                            <td contentEditable={true}
                              onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "1_6")}>{bakers.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "1_7")}>{bakers.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "1_8")}>{bakers.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "1_9")}>{bakers.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "1_10")}>{bakers.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "1_11")}>{bakers.otherNotes}</td>
                     
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle1 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "busser")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "busser")}></i>}</td>
                        {toggle1 && (
                          <>
                            <td>Busser</td>
                            <td>
                              {busser.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setBusser", "busser", "2_1")
                              }} ><div className="round"></div></div>}

                              {busser.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setBusser", "busser", "2_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {busser.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setBusser", "busser", "2_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {busser.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setBusser", "busser", "2_2")
                              }} ><div className="round"></div></div>}

                              {busser.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setBusser", "busser", "2_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {busser.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setBusser", "busser", "2_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {busser.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setBusser", "busser", "2_3")
                              }} ><div className="round"></div></div>}

                              {busser.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setBusser", "busser", "2_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {busser.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setBusser", "busser", "2_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {busser.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setBusser", "busser", "2_4")
                              }} ><div className="round"></div></div>}

                              {busser.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setBusser", "busser", "2_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {busser.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setBusser", "busser", "2_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {busser.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setBusser", "busser", "2_5")
                              }} ><div className="round"></div></div>}

                              {busser.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setBusser", "busser", "2_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {busser.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setBusser", "busser", "2_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                          
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "2_6")}>{busser.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "2_7")}>{busser.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "2_8")}>{busser.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "2_9")}>{busser.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "2_10")}>{busser.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "2_11")}>{busser.otherNotes}</td>
            
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle2 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "clown")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "clown")}></i>}</td>
                        {toggle2 && (
                          <>
                            <td>Clown</td>
                            <td>
                              {clown.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setClown", "clown", "3_1")
                              }} ><div className="round"></div></div>}

                              {clown.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setClown", "clown", "3_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {clown.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setClown", "clown", "3_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {clown.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setClown", "clown", "3_2")
                              }} ><div className="round"></div></div>}

                              {clown.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setClown", "clown", "3_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {clown.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setClown", "clown", "3_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {clown.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setClown", "clown", "3_3")
                              }} ><div className="round"></div></div>}

                              {clown.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setClown", "clown", "3_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {clown.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setClown", "clown", "3_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {clown.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setClown", "clown", "3_4")
                              }} ><div className="round"></div></div>}

                              {clown.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setClown", "clown", "3_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {clown.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setClown", "clown", "3_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {clown.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setClown", "clown", "3_5")
                              }} ><div className="round"></div></div>}

                              {clown.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setClown", "clown", "3_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {clown.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setClown", "clown", "3_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                      
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "3_6")}>{clown.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "3_7")}>{clown.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "3_8")}>{clown.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "3_9")}>{clown.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "3_10")}>{clown.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "3_11")}>{clown.otherNotes}</td>
                  
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle3 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "conspiracist")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "conspiracist")}></i>}</td>
                        {toggle3 && (
                          <>
                            <td>Conspiracist</td>
                            <td>
                              {conspiracist.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setConspiracist", "conspiracist", "4_1")
                              }} ><div className="round"></div></div>}

                              {conspiracist.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setConspiracist", "conspiracist", "4_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {conspiracist.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setConspiracist", "conspiracist", "4_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {conspiracist.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setConspiracist", "conspiracist", "4_2")
                              }} ><div className="round"></div></div>}

                              {conspiracist.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setConspiracist", "conspiracist", "4_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {conspiracist.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setConspiracist", "conspiracist", "4_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {conspiracist.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setConspiracist", "conspiracist", "4_3")
                              }} ><div className="round"></div></div>}

                              {conspiracist.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setConspiracist", "conspiracist", "4_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {conspiracist.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setConspiracist", "conspiracist", "4_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {conspiracist.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setConspiracist", "conspiracist", "4_4")
                              }} ><div className="round"></div></div>}

                              {conspiracist.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setConspiracist", "conspiracist", "4_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {conspiracist.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setConspiracist", "conspiracist", "4_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {conspiracist.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setConspiracist", "conspiracist", "4_5")
                              }} ><div className="round"></div></div>}

                              {conspiracist.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setConspiracist", "conspiracist", "4_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {conspiracist.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setConspiracist", "conspiracist", "4_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                   
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "4_6")} >{conspiracist.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "4_7")}>{conspiracist.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "4_8")}>{conspiracist.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "4_9")}>{conspiracist.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "4_10")}>{conspiracist.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "4_11")}>{conspiracist.otherNotes}</td>
                  
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle4 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "fanatic")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "fanatic")}></i>}</td>
                        {toggle4 && (
                          <>
                            <td>Fanatic</td>
                            <td>
                              {fanatic.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setFanatic", "fanatic", "5_1")
                              }} ><div className="round"></div></div>}

                              {fanatic.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setFanatic", "fanatic", "5_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {fanatic.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setFanatic", "fanatic", "5_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {fanatic.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setFanatic", "fanatic", "5_2")
                              }} ><div className="round"></div></div>}

                              {fanatic.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setFanatic", "fanatic", "5_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {fanatic.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setFanatic", "fanatic", "5_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {fanatic.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setFanatic", "fanatic", "5_3")
                              }} ><div className="round"></div></div>}

                              {fanatic.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setFanatic", "fanatic", "5_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {fanatic.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setFanatic", "fanatic", "5_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {fanatic.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setFanatic", "fanatic", "5_4")
                              }} ><div className="round"></div></div>}

                              {fanatic.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setFanatic", "fanatic", "5_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {fanatic.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setFanatic", "fanatic", "5_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {fanatic.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setFanatic", "fanatic", "5_5")
                              }} ><div className="round"></div></div>}

                              {fanatic.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setFanatic", "fanatic", "5_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {fanatic.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setFanatic", "fanatic", "5_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                        
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "5_6")}>{fanatic.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "5_7")}>{fanatic.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "5_8")}>{fanatic.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "5_9")}>{fanatic.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "5_10")}>{fanatic.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "5_11")}>{fanatic.otherNotes}</td>
                         
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle5 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "foodCritic")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "foodCritic")}></i>}</td>
                        {toggle5 && (
                          <>
                            <td>Food Critic</td>
                            <td>
                              {foodCritic.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setFoodCritic", "foodCritic", "6_1")
                              }} ><div className="round"></div></div>}

                              {foodCritic.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setFoodCritic", "foodCritic", "6_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {foodCritic.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setFoodCritic", "foodCritic", "6_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {foodCritic.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setFoodCritic", "foodCritic", "6_2")
                              }} ><div className="round"></div></div>}

                              {foodCritic.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setFoodCritic", "foodCritic", "6_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {foodCritic.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setFoodCritic", "foodCritic", "6_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {foodCritic.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setFoodCritic", "foodCritic", "6_3")
                              }} ><div className="round"></div></div>}

                              {foodCritic.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setFoodCritic", "foodCritic", "6_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {foodCritic.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setFoodCritic", "foodCritic", "6_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {foodCritic.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setFoodCritic", "foodCritic", "6_4")
                              }} ><div className="round"></div></div>}

                              {foodCritic.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setFoodCritic", "foodCritic", "6_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {foodCritic.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setFoodCritic", "foodCritic", "6_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {foodCritic.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setFoodCritic", "foodCritic", "6_5")
                              }} ><div className="round"></div></div>}

                              {foodCritic.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setFoodCritic", "foodCritic", "6_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {foodCritic.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setFoodCritic", "foodCritic", "6_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                        
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "6_6")}>{foodCritic.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "6_7")}>{foodCritic.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "6_8")}>{foodCritic.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "6_9")}>{foodCritic.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "6_10")}>{foodCritic.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "6_11")}>{foodCritic.otherNotes}</td>
                          
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle6 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "governmentAgent")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "governmentAgent")}></i>}</td>
                        {toggle6 && (
                          <>
                            <td>Government Agent</td>
                            <td>
                              {governmentAgent.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setGovernmentAgent", "governmentAgent", "7_1")
                              }} ><div className="round"></div></div>}

                              {governmentAgent.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setGovernmentAgent", "governmentAgent", "7_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {governmentAgent.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setGovernmentAgent", "governmentAgent", "7_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {governmentAgent.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setGovernmentAgent", "governmentAgent", "7_2")
                              }} ><div className="round"></div></div>}

                              {governmentAgent.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setGovernmentAgent", "governmentAgent", "7_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {governmentAgent.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setGovernmentAgent", "governmentAgent", "7_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {governmentAgent.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setGovernmentAgent", "governmentAgent", "7_3")
                              }} ><div className="round"></div></div>}

                              {governmentAgent.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setGovernmentAgent", "governmentAgent", "7_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {governmentAgent.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setGovernmentAgent", "governmentAgent", "7_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {governmentAgent.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setGovernmentAgent", "governmentAgent", "7_4")
                              }} ><div className="round"></div></div>}

                              {governmentAgent.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setGovernmentAgent", "governmentAgent", "7_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {governmentAgent.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setGovernmentAgent", "governmentAgent", "7_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {governmentAgent.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setGovernmentAgent", "governmentAgent", "7_5")
                              }} ><div className="round"></div></div>}

                              {governmentAgent.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setGovernmentAgent", "governmentAgent", "7_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {governmentAgent.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setGovernmentAgent", "governmentAgent", "7_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                      
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "7_6")}>{governmentAgent.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "7_7")}>{governmentAgent.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "7_8")}>{governmentAgent.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "7_9")}>{governmentAgent.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "7_10")}>{governmentAgent.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "7_11")}>{governmentAgent.otherNotes}</td>
                      
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle7 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "healthInspector")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "healthInspector")}></i>}</td>
                        {toggle7 && (
                          <>
                            <td>Health Inspector</td>
                            <td>
                              {healthInspector.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "sethealthInspector", "healthInspector", "8_1")
                              }} ><div className="round"></div></div>}

                              {healthInspector.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "sethealthInspector", "healthInspector", "8_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {healthInspector.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "sethealthInspector", "healthInspector", "8_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {healthInspector.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "sethealthInspector", "healthInspector", "8_2")
                              }} ><div className="round"></div></div>}

                              {healthInspector.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "sethealthInspector", "healthInspector", "8_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {healthInspector.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "sethealthInspector", "healthInspector", "8_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {healthInspector.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "sethealthInspector", "healthInspector", "8_3")
                              }} ><div className="round"></div></div>}

                              {healthInspector.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "sethealthInspector", "healthInspector", "8_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {healthInspector.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "sethealthInspector", "healthInspector", "8_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {healthInspector.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "sethealthInspector", "healthInspector", "8_4")
                              }} ><div className="round"></div></div>}

                              {healthInspector.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "sethealthInspector", "healthInspector", "8_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {healthInspector.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "sethealthInspector", "healthInspector", "8_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {healthInspector.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "sethealthInspector", "healthInspector", "8_5")
                              }} ><div className="round"></div></div>}

                              {healthInspector.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "sethealthInspector", "healthInspector", "8_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {healthInspector.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "sethealthInspector", "healthInspector", "8_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                       
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "8_6")}>{healthInspector.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "8_7")}>{healthInspector.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "8_8")}>{healthInspector.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "8_9")}>{healthInspector.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "8_10")}>{healthInspector.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "8_11")}>{healthInspector.otherNotes}</td>
                        
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle8 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "impersonator")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "impersonator")}></i>}</td>
                        {toggle8 && (
                          <>
                            <td>Impersonator</td>
                            <td>
                              {impersonator.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setImpersonator", "impersonator", "9_1")
                              }} ><div className="round"></div></div>}

                              {impersonator.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setImpersonator", "impersonator", "9_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {impersonator.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setImpersonator", "impersonator", "9_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {impersonator.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setImpersonator", "impersonator", "9_2")
                              }} ><div className="round"></div></div>}

                              {impersonator.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setImpersonator", "impersonator", "9_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {impersonator.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setImpersonator", "impersonator", "9_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {impersonator.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setImpersonator", "impersonator", "9_3")
                              }} ><div className="round"></div></div>}

                              {impersonator.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setImpersonator", "impersonator", "9_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {impersonator.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setImpersonator", "impersonator", "9_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {impersonator.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setImpersonator", "impersonator", "9_4")
                              }} ><div className="round"></div></div>}

                              {impersonator.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setImpersonator", "impersonator", "9_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {impersonator.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setImpersonator", "impersonator", "9_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {impersonator.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setImpersonator", "impersonator", "9_5")
                              }} ><div className="round"></div></div>}

                              {impersonator.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setImpersonator", "impersonator", "9_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {impersonator.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setImpersonator", "impersonator", "9_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                           
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "9_6")}>{impersonator.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "9_7")}>{impersonator.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "9_8")}>{impersonator.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "9_9")}>{impersonator.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "9_10")}>{impersonator.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "9_11")}>{impersonator.otherNotes}</td>
                     
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle9 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "innocentPatron")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "innocentPatron")}></i>}</td>
                        {toggle9 && (
                          <>
                            <td>Innocent Patron</td>
                            <td>
                              {innocentPatron.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setInnocentPatron", "innocentPatron", "10_1")
                              }} ><div className="round"></div></div>}

                              {innocentPatron.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setInnocentPatron", "innocentPatron", "10_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {innocentPatron.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setInnocentPatron", "innocentPatron", "10_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {innocentPatron.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setInnocentPatron", "innocentPatron", "10_2")
                              }} ><div className="round"></div></div>}

                              {innocentPatron.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setInnocentPatron", "innocentPatron", "10_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {innocentPatron.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setInnocentPatron", "innocentPatron", "10_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {innocentPatron.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setInnocentPatron", "innocentPatron", "10_3")
                              }} ><div className="round"></div></div>}

                              {innocentPatron.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setInnocentPatron", "innocentPatron", "10_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {innocentPatron.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setInnocentPatron", "innocentPatron", "10_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {innocentPatron.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setInnocentPatron", "innocentPatron", "10_4")
                              }} ><div className="round"></div></div>}

                              {innocentPatron.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setInnocentPatron", "innocentPatron", "10_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {innocentPatron.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setInnocentPatron", "innocentPatron", "10_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {innocentPatron.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setInnocentPatron", "innocentPatron", "10_5")
                              }} ><div className="round"></div></div>}

                              {innocentPatron.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setInnocentPatron", "innocentPatron", "10_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {innocentPatron.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setInnocentPatron", "innocentPatron", "10_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                         
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "10_6")}>{innocentPatron.bathroom}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "10_7")}>{innocentPatron.chambers}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "10_8")}>{innocentPatron.kitchen}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "10_9")}>{innocentPatron.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "10_10")}>{innocentPatron.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "10_11")}>{innocentPatron.otherNotes}</td>
                       
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle11 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "intern")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "intern")}></i>}</td>
                        {toggle11 && (
                          <>
                            <td>Intern</td>
                            <td>
                              {intern.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setIntern", "intern", "11_1")
                              }} ><div className="round"></div></div>}

                              {intern.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setIntern", "intern", "11_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {intern.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setIntern", "intern", "11_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {intern.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setIntern", "intern", "11_2")
                              }} ><div className="round"></div></div>}

                              {intern.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setIntern", "intern", "11_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {intern.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setIntern", "intern", "11_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {intern.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setIntern", "intern", "11_3")
                              }} ><div className="round"></div></div>}

                              {intern.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setIntern", "intern", "11_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {intern.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setIntern", "intern", "11_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {intern.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setIntern", "intern", "11_4")
                              }} ><div className="round"></div></div>}

                              {intern.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setIntern", "intern", "11_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {intern.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setIntern", "intern", "11_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {intern.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setIntern", "intern", "11_5")
                              }} ><div className="round"></div></div>}

                              {intern.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setIntern", "intern", "11_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {intern.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setIntern", "intern", "11_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                    
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "11_6")}>{intern.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "11_7")}>{intern.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "11_8")}>{intern.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "11_9")}>{intern.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "11_10")}>{intern.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "11_11")}>{intern.otherNotes}</td>
                 
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle12 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "neighbor")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "neighbor")}></i>}</td>
                        {toggle12 && (
                          <>
                            <td>Neighbor</td>
                            <td>
                              {neighbor.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setNeighbor", "neighbor", "12_1")
                              }} ><div className="round"></div></div>}

                              {neighbor.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setNeighbor", "neighbor", "12_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {neighbor.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setNeighbor", "neighbor", "12_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {neighbor.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setNeighbor", "neighbor", "12_2")
                              }} ><div className="round"></div></div>}

                              {neighbor.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setNeighbor", "neighbor", "12_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {neighbor.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setNeighbor", "neighbor", "12_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {neighbor.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setNeighbor", "neighbor", "12_3")
                              }} ><div className="round"></div></div>}

                              {neighbor.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setNeighbor", "neighbor", "12_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {neighbor.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setNeighbor", "neighbor", "12_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {neighbor.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setNeighbor", "neighbor", "12_4")
                              }} ><div className="round"></div></div>}

                              {neighbor.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setNeighbor", "neighbor", "12_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {neighbor.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setNeighbor", "neighbor", "12_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {neighbor.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setNeighbor", "neighbor", "12_5")
                              }} ><div className="round"></div></div>}

                              {neighbor.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setNeighbor", "neighbor", "12_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {neighbor.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setNeighbor", "neighbor", "12_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                        
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "12_6")}>{neighbor.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "12_7")}>{neighbor.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "12_8")}>{neighbor.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "12_9")}>{neighbor.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "12_10")}>{neighbor.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "12_11")}>{neighbor.otherNotes}</td>
                      
                          </>
                        )}
                      </tr>



                      <tr>
                        <td className="minus">{toggle13 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "niece")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "niece")}></i>}</td>
                        {toggle13 && (
                          <>
                            <td>Niece/Nephew</td>
                            <td>
                              {niece.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setNiece", "niece", "13_1")
                              }} ><div className="round"></div></div>}

                              {niece.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setNiece", "niece", "13_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {niece.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setNiece", "niece", "13_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {niece.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setNiece", "niece", "13_2")
                              }} ><div className="round"></div></div>}

                              {niece.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setNiece", "niece", "13_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {niece.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setNiece", "niece", "13_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {niece.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setNiece", "niece", "13_3")
                              }} ><div className="round"></div></div>}

                              {niece.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setNiece", "niece", "13_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {niece.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setNiece", "niece", "13_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {niece.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setNiece", "niece", "13_4")
                              }} ><div className="round"></div></div>}

                              {niece.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setNiece", "niece", "13_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {niece.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setNiece", "niece", "13_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {niece.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setNiece", "niece", "13_5")
                              }} ><div className="round"></div></div>}

                              {niece.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setNiece", "niece", "13_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {niece.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setNiece", "niece", "13_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                         
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "13_6")}>{niece.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "13_7")}>{niece.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "13_8")}>{niece.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "13_9")}>{niece.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "13_10")}>{niece.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "13_11")}>{niece.otherNotes}</td>
                      
                          </>
                        )}
                      </tr>



                      <tr>
                        <td className="minus">{toggle14 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "protestor")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "protestor")}></i>}</td>
                        {toggle14 && (
                          <>
                            <td>Protestor</td>
                            <td>
                              {protestor.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setProtestor", "protestor", "14_1")
                              }} ><div className="round"></div></div>}

                              {protestor.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setProtestor", "protestor", "14_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {protestor.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setProtestor", "protestor", "14_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {protestor.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setProtestor", "protestor", "14_2")
                              }} ><div className="round"></div></div>}

                              {protestor.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setProtestor", "protestor", "14_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {protestor.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setProtestor", "protestor", "14_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {protestor.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setProtestor", "protestor", "14_3")
                              }} ><div className="round"></div></div>}

                              {protestor.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setProtestor", "protestor", "14_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {protestor.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setProtestor", "protestor", "14_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {protestor.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setProtestor", "protestor", "14_4")
                              }} ><div className="round"></div></div>}

                              {protestor.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setProtestor", "protestor", "14_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {protestor.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setProtestor", "protestor", "14_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {protestor.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setProtestor", "protestor", "14_5")
                              }} ><div className="round"></div></div>}

                              {protestor.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setProtestor", "protestor", "14_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {protestor.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setProtestor", "protestor", "14_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                   
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "14_6")}>{protestor.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "14_7")}>{protestor.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "14_8")}>{protestor.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "14_9")}>{protestor.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "14_10")}>{protestor.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "14_11")}>{protestor.otherNotes}</td>
                        
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle15 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "rival")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "rival")}></i>}</td>
                        {toggle15 && (
                          <>
                            <td>Rival</td>
                            <td>
                              {rival.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setRival", "rival", "15_1")
                              }} ><div className="round"></div></div>}

                              {rival.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setRival", "rival", "15_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {rival.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setRival", "rival", "15_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {rival.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setRival", "rival", "15_2")
                              }} ><div className="round"></div></div>}

                              {rival.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setRival", "rival", "15_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {rival.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setRival", "rival", "15_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {rival.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setRival", "rival", "15_3")
                              }} ><div className="round"></div></div>}

                              {rival.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setRival", "rival", "15_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {rival.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setRival", "rival", "15_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {rival.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setRival", "rival", "15_4")
                              }} ><div className="round"></div></div>}

                              {rival.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setRival", "rival", "15_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {rival.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setRival", "rival", "15_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {rival.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setRival", "rival", "15_5")
                              }} ><div className="round"></div></div>}

                              {rival.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setRival", "rival", "15_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {rival.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setRival", "rival", "15_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                      
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "15_6")}>{rival.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "15_7")}>{rival.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "15_8")}>{rival.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "15_9")}>{rival.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "15_10")}>{rival.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "15_11")}>{rival.otherNotes}</td>
                      
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle16 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "salesperson")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "salesperson")}></i>}</td>
                        {toggle16 && (
                          <>
                            <td>Salesperson</td>
                            <td>
                              {salesperson.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setSalesperson", "salesperson", "16_1")
                              }} ><div className="round"></div></div>}

                              {salesperson.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setSalesperson", "salesperson", "16_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {salesperson.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setSalesperson", "salesperson", "16_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {salesperson.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setSalesperson", "salesperson", "16_2")
                              }} ><div className="round"></div></div>}

                              {salesperson.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setSalesperson", "salesperson", "16_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {salesperson.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setSalesperson", "salesperson", "16_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {salesperson.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setSalesperson", "salesperson", "16_3")
                              }} ><div className="round"></div></div>}

                              {salesperson.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setSalesperson", "salesperson", "16_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {salesperson.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setSalesperson", "salesperson", "16_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {salesperson.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setSalesperson", "salesperson", "16_4")
                              }} ><div className="round"></div></div>}

                              {salesperson.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setSalesperson", "salesperson", "16_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {salesperson.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setSalesperson", "salesperson", "16_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {salesperson.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setSalesperson", "salesperson", "16_5")
                              }} ><div className="round"></div></div>}

                              {salesperson.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setSalesperson", "salesperson", "16_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {salesperson.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setSalesperson", "salesperson", "16_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                           
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "16_6")}>{salesperson.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "16_7")}>{salesperson.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "16_8")}>{salesperson.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "16_9")}>{salesperson.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "16_10")}>{salesperson.diningFloor}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "16_11")}>{salesperson.otherNotes}</td>
                         
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle17 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "spouse")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "spouse")}></i>}</td>
                        {toggle17 && (
                          <>
                            <td>Spouse (Patron)</td>
                            <td>
                              {spouse.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setSpouse", "spouse", "17_1")
                              }} ><div className="round"></div></div>}

                              {spouse.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setSpouse", "spouse", "17_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouse.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setSpouse", "spouse", "17_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {spouse.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setSpouse", "spouse", "17_2")
                              }} ><div className="round"></div></div>}

                              {spouse.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setSpouse", "spouse", "17_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouse.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setSpouse", "spouse", "17_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {spouse.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setSpouse", "spouse", "17_3")
                              }} ><div className="round"></div></div>}

                              {spouse.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setSpouse", "spouse", "17_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouse.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setSpouse", "spouse", "17_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {spouse.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setSpouse", "spouse", "17_4")
                              }} ><div className="round"></div></div>}

                              {spouse.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setSpouse", "spouse", "17_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouse.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setSpouse", "spouse", "17_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {spouse.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setSpouse", "spouse", "17_5")
                              }} ><div className="round"></div></div>}

                              {spouse.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setSpouse", "spouse", "17_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouse.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setSpouse", "spouse", "17_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                           
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "17_6")}>{spouse.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "17_7")}>{spouse.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "17_8")}>{spouse.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "17_9")}>{spouse.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "17_10")}>{spouse.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "17_11")}>{spouse.otherNotes}</td>
                           
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle18 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "spouseSpouse")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "spouseSpouse")}></i>}</td>
                        {toggle18 && (
                          <>
                            <td>Spouse&apos;s Spouse</td>
                            <td>
                              {spouseSpouse.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setSpouseSpouse", "spouseSpouse", "18_1")
                              }} ><div className="round"></div></div>}

                              {spouseSpouse.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setSpouseSpouse", "spouseSpouse", "18_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouseSpouse.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setSpouseSpouse", "spouseSpouse", "18_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {spouseSpouse.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setSpouseSpouse", "spouseSpouse", "18_2")
                              }} ><div className="round"></div></div>}

                              {spouseSpouse.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setSpouseSpouse", "spouseSpouse", "18_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouseSpouse.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setSpouseSpouse", "spouseSpouse", "18_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {spouseSpouse.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setSpouseSpouse", "spouseSpouse", "18_3")
                              }} ><div className="round"></div></div>}

                              {spouseSpouse.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setSpouseSpouse", "spouseSpouse", "18_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouseSpouse.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setSpouseSpouse", "spouseSpouse", "18_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {spouseSpouse.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setSpouseSpouse", "spouseSpouse", "18_4")
                              }} ><div className="round"></div></div>}

                              {spouseSpouse.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setSpouseSpouse", "spouseSpouse", "18_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouseSpouse.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setSpouseSpouse", "spouseSpouse", "18_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {spouseSpouse.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setSpouseSpouse", "spouseSpouse", "18_5")
                              }} ><div className="round"></div></div>}

                              {spouseSpouse.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setSpouseSpouse", "spouseSpouse", "18_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {spouseSpouse.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setSpouseSpouse", "spouseSpouse", "18_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                         
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "18_6")}>{spouseSpouse.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "18_7")}>{spouseSpouse.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "18_8")}>{spouseSpouse.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "18_9")}>{spouseSpouse.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "18_10")}>{spouseSpouse.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "18_11")}>{spouseSpouse.otherNotes}</td>
                           
                          </>
                        )}
                      </tr>


                      <tr>
                        <td className="minus">{toggle19 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "suspiciousPatron")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "suspiciousPatron")}></i>}</td>
                        {toggle19 && (
                          <>
                            <td>Suspicious Patron</td>
                            <td>
                              {suspiciousPatron.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setSuspiciousPatron", "suspiciousPatron", "19_1")
                              }} ><div className="round"></div></div>}

                              {suspiciousPatron.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setSuspiciousPatron", "suspiciousPatron", "19_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {suspiciousPatron.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setSuspiciousPatron", "suspiciousPatron", "19_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {suspiciousPatron.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setSuspiciousPatron", "suspiciousPatron", "19_2")
                              }} ><div className="round"></div></div>}

                              {suspiciousPatron.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setSuspiciousPatron", "suspiciousPatron", "19_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {suspiciousPatron.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setSuspiciousPatron", "suspiciousPatron", "19_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {suspiciousPatron.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setSuspiciousPatron", "suspiciousPatron", "19_3")
                              }} ><div className="round"></div></div>}

                              {suspiciousPatron.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setSuspiciousPatron", "suspiciousPatron", "19_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {suspiciousPatron.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setSuspiciousPatron", "suspiciousPatron", "19_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {suspiciousPatron.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setSuspiciousPatron", "suspiciousPatron", "19_4")
                              }} ><div className="round"></div></div>}

                              {suspiciousPatron.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setSuspiciousPatron", "suspiciousPatron", "19_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {suspiciousPatron.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setSuspiciousPatron", "suspiciousPatron", "19_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {suspiciousPatron.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setSuspiciousPatron", "suspiciousPatron", "19_5")
                              }} ><div className="round"></div></div>}

                              {suspiciousPatron.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setSuspiciousPatron", "suspiciousPatron", "19_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {suspiciousPatron.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setSuspiciousPatron", "suspiciousPatron", "19_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                        
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "19_6")}>{suspiciousPatron.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "19_7")}>{suspiciousPatron.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "19_8")}>{suspiciousPatron.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "19_9")}>{suspiciousPatron.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "19_10")}>{suspiciousPatron.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "19_11")}>{suspiciousPatron.otherNotes}</td>
                           
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle21 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "teacher")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "teacher")}></i>}</td>
                        {toggle21 && (
                          <>
                            <td>Teacher</td>
                            <td>
                              {teacher.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setTeacher", "teacher", "20_1")
                              }} ><div className="round"></div></div>}

                              {teacher.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setTeacher", "teacher", "20_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {teacher.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setTeacher", "teacher", "20_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {teacher.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setTeacher", "teacher", "20_2")
                              }} ><div className="round"></div></div>}

                              {teacher.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setTeacher", "teacher", "20_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {teacher.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setTeacher", "teacher", "20_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {teacher.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setTeacher", "teacher", "20_3")
                              }} ><div className="round"></div></div>}

                              {teacher.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setTeacher", "teacher", "20_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {teacher.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setTeacher", "teacher", "20_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {teacher.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setTeacher", "teacher", "20_4")
                              }} ><div className="round"></div></div>}

                              {teacher.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setTeacher", "teacher", "20_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {teacher.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setTeacher", "teacher", "20_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {teacher.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setTeacher", "teacher", "20_5")
                              }} ><div className="round"></div></div>}

                              {teacher.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setTeacher", "teacher", "20_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {teacher.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setTeacher", "teacher", "20_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                           
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "20_6")}>{teacher.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "20_7")}>{teacher.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "20_8")}>{teacher.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "20_9")}>{teacher.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "20_10")}>{teacher.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "20_11")}>{teacher.otherNotes}</td>
                        
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle22 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "tourGuide")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "tourGuide")}></i>}</td>
                        {toggle22 && (
                          <>
                            <td>Tour Guide</td>
                            <td>
                              {tourGuide.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setTourGuide", "tourGuide", "21_1")
                              }} ><div className="round"></div></div>}

                              {tourGuide.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setTourGuide", "tourGuide", "21_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourGuide.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setTourGuide", "tourGuide", "21_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {tourGuide.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setTourGuide", "tourGuide", "21_2")
                              }} ><div className="round"></div></div>}

                              {tourGuide.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setTourGuide", "tourGuide", "21_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourGuide.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setTourGuide", "tourGuide", "21_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {tourGuide.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setTourGuide", "tourGuide", "21_3")
                              }} ><div className="round"></div></div>}

                              {tourGuide.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setTourGuide", "tourGuide", "21_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourGuide.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setTourGuide", "tourGuide", "21_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {tourGuide.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setTourGuide", "tourGuide", "21_4")
                              }} ><div className="round"></div></div>}

                              {tourGuide.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setTourGuide", "tourGuide", "21_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourGuide.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setTourGuide", "tourGuide", "21_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {tourGuide.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setTourGuide", "tourGuide", "21_5")
                              }} ><div className="round"></div></div>}

                              {tourGuide.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setTourGuide", "tourGuide", "21_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourGuide.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setTourGuide", "tourGuide", "21_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "21_6")}>{tourGuide.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "21_7")}>{tourGuide.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "21_8")}>{tourGuide.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "21_9")}>{tourGuide.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "21_10")}>{tourGuide.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "21_11")}>{tourGuide.otherNotes}</td>
                        
                          </>
                        )}
                      </tr>

                      <tr>
                        <td className="minus">{toggle23 ? <i className="fa-solid fa-minus displayNone" onClick={() => setToggleValue(false, "tourist")}></i> : <i className="fa-solid fa-minus expandChildTable" onClick={() => setToggleValue(true, "tourist")}></i>}</td>
                        {toggle23 && (
                          <>
                            <td>Tourist</td>
                            <td>
                              {tourist.allergen == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "allergen", "setTourist", "tourist", "22_1")
                              }} ><div className="round"></div></div>}

                              {tourist.allergen == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "allergen", "setTourist", "tourist", "22_1")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourist.allergen == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "allergen", "setTourist", "tourist", "22_1")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                            <td>
                              {tourist.axe == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "axe", "setTourist", "tourist", "22_2")
                              }} ><div className="round"></div></div>}

                              {tourist.axe == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "axe", "setTourist", "tourist", "22_2")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourist.axe == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "axe", "setTourist", "tourist", "22_2")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {tourist.breadstick == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "breadstick", "setTourist", "tourist", "22_3")
                              }} ><div className="round"></div></div>}

                              {tourist.breadstick == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "breadstick", "setTourist", "tourist", "22_3")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourist.breadstick == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "breadstick", "setTourist", "tourist", "22_3")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {tourist.gun == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "gun", "setTourist", "tourist", "22_4")
                              }} ><div className="round"></div></div>}

                              {tourist.gun == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "gun", "setTourist", "tourist", "22_4")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourist.gun == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "gun", "setTourist", "tourist", "22_4")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>

                            <td>
                              {tourist.poison == "off" && <div style={{ color: "gray" }} className="checks" onClick={() => {
                                updateTherapistDetails("yes", "poison", "setTourist", "tourist", "22_5")
                              }} ><div className="round"></div></div>}

                              {tourist.poison == "yes" && <div style={{ color: "darkgreen" }} className="checks" onClick={() => {
                                updateTherapistDetails("no", "poison", "setTourist", "tourist", "22_5")
                              }} ><div className="circle">
                                  <div className="checkmark"></div>
                                </div></div>}

                              {tourist.poison == "no" && <div style={{ color: "maroon" }} className="checks" onClick={() => {
                                updateTherapistDetails("off", "poison", "setTourist", "tourist", "22_5")
                              }} ><div className="circleRed">
                                  <div className="checkmarkRed"></div>
                                </div></div>}
                            </td>
                         
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "22_6")}>{tourist.bathroom}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "22_7")}>{tourist.chambers}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "22_8")}>{tourist.kitchen}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "22_9")}>{tourist.diningFloor}</td>
                            <td contentEditable onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "22_10")}>{tourist.dumpster}</td>
                            <td contentEditable={true} onInput={(e: any) => updateOnchangeDetails(e.currentTarget.textContent, "22_11")}>{tourist.otherNotes}</td>
                         
                          </>
                        )}
                      </tr>

                    </tbody>
                  </table>

                </div>
              </div>

            </div>
            <div className="col-md-4">
              <div className="card-body">
                <div className="row noteText chat-conversation ">
                  <div
                    className="scrollbar-container ps ps--active-y"
                    style={{ height: "15vh" }}
                  >
                    <Input
                      type="textarea"
                      className="form-control mb-2"
                      id="formrow-firstname-Input"
                      style={{ minHeight: "130px" }}
                      onChange={e => updateInstanceNote(e.target.value)}
                      defaultValue={allNotes?.allNotes}
                      placeholder="Type your notes here..."
                      name="articleTitle"
                      maxLength={430}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </>
      ) : (

        <>
          {isLoaded ? <BounceLoader
            color="#17335e"
            className="bouncer"
            size={50}
          /> :
            <h2 className="bouncerText">No note panel found</h2>
          }</>
      )}
    </>

  )
}

export default HomePage;